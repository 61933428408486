import { Avatar, Box, Card, CardActions, CardHeader, Grid, IconButton, Paper, Stack, Typography, useTheme, useThemeProps } from '@mui/material'
import React, { memo } from 'react'
import CropFreeIcon from '@mui/icons-material/CropFree';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloudIcon from '@mui/icons-material/Cloud';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import AutorenewIcon from '@mui/icons-material/Autorenew';
function TempInfoStrip() {
    const { spacing } = useTheme()
    const styles = {
        IconStyle: {
            color: 'primary.main',
            fontSize: '1rem'
        }
    }
    return (
        <Card>
            <CardActions sx={{ borderTop: 0, display: "flex", p: 1, justifyContent: "space-between", alignItems: "center" }}>
                <Stack rowGap={spacing(1)} direction={'row'} alignItems={'center'}>
                    <CalendarMonthIcon sx={styles.IconStyle} />
                    <Typography>10 : 27 AM  Saturday, October 21, 2023</Typography>
                </Stack>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <CloudIcon sx={styles.IconStyle} />
                    <Typography>Current Weather</Typography>
                </Stack>
                <Stack gap={1} direction={'row'} alignItems={'center'}>
                    <ThermostatIcon sx={styles.IconStyle} />
                    <Typography>Today’s AVG PR : 21 Oct 2023</Typography>
                </Stack>
                <Stack gap={1} direction={'row'} alignItems={'center'}>
                    <ThermostatIcon sx={styles.IconStyle} />
                    <Typography>Temperature : 25.4C</Typography>
                </Stack>
                <Stack gap={1} direction={'row'} alignItems={'center'}>
                    <WaterDropIcon sx={styles.IconStyle} />
                    <Typography>Humidity : 36%</Typography>
                </Stack>
            </CardActions>
        </Card>
    )
}

export default memo(TempInfoStrip)