import React from "react";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register required Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const PRCUFGraph = () => {
    // Chart data
    const data = {
        labels: ['08 Oct', '09 Oct', '10 Oct', '11 Oct', '12 Oct', '13 Oct', '14 Oct'],
        datasets: [
            {
                label: 'PR',
                data: [4.0, 4.5, 4.2, 4.7, 4.1, 4.3, 4.0],
                backgroundColor: 'rgba(75, 192, 192, 0.7)', // Green for PR
            },
            {
                label: 'CUF',
                data: [2.5, 2.6, 2.7, 2.8, 2.5, 2.6, 2.4],
                backgroundColor: 'rgba(153, 102, 255, 0.7)', // Purple for CUF
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'PR | CUF',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Performance Ratio (PR) / CUF (%)',
                },
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default PRCUFGraph;
