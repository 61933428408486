import React, { memo, useContext } from 'react'
import { Route, Routes } from "react-router-dom";
import { AuthenticationContext } from '../context/AuthenticationContext'
import { Box } from '@mui/material';
import EventLogReports from '../pages/admin/page/event_log_reports/EventLogReports';
import MsitMain from '../pages/admin/page/msit/MsitMain';
import StringInverter from '../pages/admin/page/msit/stringInverter/StringInverter';



const PrivateRoute = React.lazy(() => import('./stacks/PrivateRoute'))
const Dashboard = React.lazy(() => import('../pages/admin/page/dashboard/Dashboard'))
const Analysis = React.lazy(() => import('../pages/admin/page/analysis/Analysis'))
const PvMonitoring = React.lazy(() => import('../pages/admin/page/pv_monitoring/PvMonitoring'))
const WMS = React.lazy(() => import('../pages/admin/page/wms/WMS'))
const PublicRoute = React.lazy(() => import('./stacks/PublicRoute'))
const Login = React.lazy(() => import('../pages/auth/Login'))
const EnergyReporting = React.lazy(() => import('../pages/admin/page/energy_reporting/EnergyReporting'))
const Inverter = React.lazy(() => import('../pages/admin/page/inverter/Inverter'))
const Inverter1 = React.lazy(() => import('../pages/admin/page/inverter1/Inverter1'))
const Grid = React.lazy(() => import('../pages/admin/page/grid/Grid'))
const DG = React.lazy(() => import('../pages/admin/page/dg/DG'))
const EnergyMeter = React.lazy(() => import('../pages/admin/page/energy_meter/EnergyMeter'))
const SMB = React.lazy(() => import('../pages/admin/page/smb/SMB'))
const MainRouter = () => {
    const { IsLogged } = useContext(AuthenticationContext);
    return (
        <Routes>
            {
                IsLogged ?
                    <Route path="/" element={<PrivateRoute />}>
                        <Route index element={<WMS/>} />
                        <Route path='dashboard' element={<Dashboard />} />

                        <Route path='inverter' element={<Inverter />} />
                        <Route path='analysis' element={<Analysis />} />


                        <Route path='inverter1' element={<Inverter1 />} />
                        <Route path='grid' element={<Grid />} />
                        <Route path='dg' element={<DG />} />
                        <Route path='energy-meter' element={<EnergyMeter />} />
                        <Route path='smb' element={<SMB />} />
                        <Route path='pv_monitoring' element={<PvMonitoring />} />
                        
                        <Route path='energy-reporting' element={<EnergyReporting />} />


                        <Route path='event-log-reports' element={<EventLogReports />} />


                        <Route path="msit" element={<MsitMain />}>
                            <Route index element={<StringInverter />} /> {/* Default child route */}
                        </Route>;

                        <Route path="*" element={<Box>Page Not Found</Box>} />
                    </Route>
                    :
                    <Route path="login" element={<PublicRoute />}>
                        <Route index element={<Login />} />
                        {/* <Route path="services" element={<Services />} />
                       <Route path="about" element={<About />} /> */}
                        <Route path="*" element={<Box>Page Not Found</Box>} />
                    </Route>
            }
        </Routes>


    )
}


export default memo(MainRouter)
