import { Box, Card, CardActions, CardHeader, Paper, Stack, Typography, useTheme } from '@mui/material'
import React, { memo } from 'react'

function CapacityCard() {
    console.log('CapacityCard')
    const { spacing } = useTheme()
    return (
        <Card>
            <CardHeader
                sx={{ bgcolor: 'primary.main' }}
                title={<Typography color={'background.default'} variant='body2'>Load</Typography>}
            />
            <CardActions>
                <Box flex={1}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography>DC</Typography>
                        <Typography>650.3 (KWp)</Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography>DC</Typography>
                        <Typography>650.3 (KWp)</Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography>DC</Typography>
                        <Typography>650.3 (KWp)</Typography>
                    </Box>
                </Box>
            </CardActions>
        </Card>
    )
}

export default memo(CapacityCard)