import { Box, Card, CardActions, CardContent, CardMedia, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { memo, useContext } from 'react'
import { AuthenticationContext } from '../../context/AuthenticationContext'
import { WmcContext } from '../../context/WmcContext'
import { WEB_KEY } from '../../api/AxiosApi'
import Image from 'mui-image'

function TopHeader() {
    const { LoggedInUserInfo } = useContext(AuthenticationContext)
    const { BrandLogo } = useContext(WmcContext)
    const { breakpoints, spacing } = useTheme()
    const LOGIN_ID = localStorage.getItem('LoginID')
    // const LOC_CODE = localStorage.getItem('LCODE')
    const matches = useMediaQuery(breakpoints.down('xl'));
    // console.log('DATAUSER', LoggedInUserInfo)
    const LOC_CODE = LoggedInUserInfo?.Loc_Code
    const PASS_KEY = "xxxxxx"
    // const ImageUrl = "https://log.solaramr.com/api/CMN_02/GET_COM_LOGO?LOGIN_ID=2402104&LOC_CODE=200ET&PASS_KEY=xxxxxx&WEB_KEY=WEBXYZ321"
    const ImageUrl = `https://log.solaramr.com/api/CMN_02/GET_COM_LOGO?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}`
    // console.log('LOGO', ImageUrl)
    return (
        <Stack rowGap={spacing(1)}>
            <Card elevation={0} sx={{ bgcolor: 'primary.light' }}>
                <CardActions>
                    <marquee>
                        <Typography variant='body1' color={'background.default'}>
                            Industrial Energy Management System Flow Meter & Ground Water Monitoring Smart Metering and DLMS Solution Industrial Energy Management System Flow Meter & Ground Water Monitoring
                        </Typography>
                    </marquee>
                </CardActions>
            </Card>
            <Grid container spacing={spacing(1)}>
                <Grid item xs={12} sm={12} md={4}>
                    <Card sx={{ height: matches ? 50 : 70, p: spacing(1), display: 'flex', alignItems: "center" }}>
                        <Stack>
                            <Typography textAlign={'start'} variant='body1'>{LoggedInUserInfo?.username}</Typography>
                            <Typography textAlign={'start'} variant='body1'>Plant Capacity : {LoggedInUserInfo?.PLANT_CAPACITY}</Typography>
                        </Stack>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Card sx={{ height: matches ? 50 : 70, p: spacing(1), display: 'flex', alignItems: "center" }}>
                        <Stack>
                            <Typography variant='body1'>{LoggedInUserInfo?.MSG}</Typography>
                        </Stack>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} display={'flex'} justifyContent={'center'}>
                    <Image fit='fill'
                        wrapperStyle={{ height: matches ? 50 : 70 }}
                        src={ImageUrl ? ImageUrl : require('../../assets/img/basic/battery.png')}
                    />
                </Grid>
            </Grid>
        </Stack >
    )
}

export default memo(TopHeader)
