import { Outlet } from "react-router-dom";
import Msit from "./Msit";


export default function MsitMain() {
    return (
        <>
            <div className="row container-fluid d-flex flex-column p-0 m-0 w-100">
                <div className="logmain_upper w-100"><Msit /></div>
                <div className="logmain_last w-100"><Outlet /></div>
            </div>


        </>
    )
}

