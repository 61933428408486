import React from "react";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register required Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const YieldGraph = () => {
    // Chart data
    const data = {
        labels: ['08 Oct', '09 Oct', '10 Oct', '11 Oct', '12 Oct', '13 Oct', '14 Oct'],
        datasets: [
            {
                label: 'Daily Yield (kWh)',
                data: [105, 110, 100, 115, 105.7, 120, 90],
                backgroundColor: 'rgba(54, 162, 235, 0.7)', // Blue bars
                yAxisID: 'y', // First y-axis
            },
            {
                label: 'Specific Yield',
                data: [1.0, 1.1, 1.05, 1.2, 1.32, 1.1, 1.0],
                backgroundColor: 'rgba(255, 206, 86, 0.7)', // Yellow bars
                yAxisID: 'y1', // Second y-axis
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Site Yield | Specific Yield (kWh)',
            },
            tooltip: {
                enabled: true,
            }
        },
        scales: {
            y: {
                type: 'linear',
                position: 'left',
                title: {
                    display: true,
                    text: 'Daily Yield (kWh)',
                },
            },
            y1: {
                type: 'linear',
                position: 'right',
                title: {
                    display: true,
                    text: 'Specific Yield',
                },
                grid: {
                    drawOnChartArea: false, // Removes grid from second axis
                },
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default YieldGraph;
