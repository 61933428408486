import YieldGraph from "../graph/Graph"
import PRCUFGraph from "../graph/SingleGraph"
import "../MsitMain.css"

export default function StringInverter() {
  return (
    <>
    
    <div className="container-fluid">
      {/* Table Section */}
      <div className="row mb-4">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ fontSize: "13px", fontWeight: "bold" }}>Name</th>
                  <th>Capacity</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Specific Yield</th>
                  <th>Today Yield</th>
                  <th>Total Yield</th>
                  <th>Output Power</th>
                  <th>Input Power</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Inverter-1</td>
                  <td>50KW</td>
                  <td>Roof</td>
                  <td>No-data</td>
                  <td>0.06</td>
                  <td>1.8KWH</td>
                  <td>2.18MWH</td>
                  <td>50KW</td>
                  <td>50KW</td>
                  <td>14/10/2024</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Graph Section */}
      <div className="row">
        {/* Graph 1 */}
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="card p-3 shadow-sm">
            <div className="d-flex justify-content-between align-items-center">
              <h6 style={{ fontSize: "14px", margin: 0 }}>Site Yield/Specific Yield</h6>
              <button
                className="btn btn-primary btn-sm"
                style={{ padding: "5px 15px" }}
              >
                Week
              </button>
            </div>
            <div className="mt-3">
              <YieldGraph />
            </div>
          </div>
        </div>

        {/* Graph 2 */}
        <div className="col-lg-6 col-md-12">
          <div className="card p-3 shadow-sm">
            <div className="d-flex justify-content-between align-items-center">
              <h6 style={{ fontSize: "14px", margin: 0 }}>PR/CUF</h6>
              <button
                className="btn btn-primary btn-sm"
                style={{ padding: "5px 15px" }}
              >
                Week
              </button>
            </div>
            <div className="mt-3">
              <PRCUFGraph />
            </div>
          </div>
        </div>
      </div>
    </div>


    </>
  )
}