import CapacityCard from "../../../../components/dashboard/tab1Component/CapacityCard";
import TempInfoStrip from "../../../../components/dashboard/TempInfoStrip";
import TopHeader from "../../../../components/general/TopHeader";
import WmsHeader from "../../../../components/general/WmsHeader";
import WmsCard from "../../../../components/WMS/WmsCard";

export default function EventLogReports(){
    return(
        <>
       <TopHeader/>
       <p>This is event_log_reports</p>
      
        </>
    )
}