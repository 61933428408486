import { ArrowDownward, ArrowDropDown, ArrowDropDownCircleRounded, ArrowUpward, WbSunnyOutlined } from '@mui/icons-material'
import { Box, Card, CardActions, Paper, Stack, Typography, useTheme } from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import React from 'react'
import GaugeChart from 'react-gauge-chart'

export default function WmsCard({ tempMeters, minDate, maxDate, avgDate, meterLiveValue, WmsCardStyle, minValue, maxValue, avgValue, StartIcon, title, boldTitle, rightTitle, LiveDate }) {
    const { palette, spacing } = useTheme()
    return (
        <Card sx={[WmsCardStyle, {}]}>
            <CardActions sx={{ justifyContent: "space-between", alignItems: 'center' }}>
                <Stack>
                    {StartIcon ? StartIcon : null}
                </Stack>
                <Stack rowGap={spacing(1)} sx={{ flex: 1 }}>
                    <Typography variant='h5' noWrap>{title}</Typography>
                    <Stack direction={'row'} sx={{ display: "flex" }}>
                        <Typography noWrap variant='h4' flex={1} sx={{ fontWeight: "500" }}>{boldTitle}</Typography>
                        {
                            rightTitle ? <Typography variant='h5' noWrap>
                                {
                                    rightTitle > 0 ?
                                        <ArrowUpward sx={{ bgcolor: "white", color: "green", fontSize: '1.2rem' }} />
                                        : <ArrowDownward sx={{ bgcolor: "white", color: "red", fontSize: '1.2rem' }} />
                                }
                                {/* <ArrowDropDownIcon sx={{ bgcolor: "white", color: "red" }} fontSize='small' /> */}
                                {rightTitle ? rightTitle : '_____'}
                            </Typography> : null
                        }

                    </Stack>
                    <Stack sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography pography variant='body2'>{LiveDate}</Typography>
                    </Stack>
                    <Stack direction={'row'} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        {
                            minValue ? <Typography variant='body2' noWrap>Min <br /> {minValue}</Typography> : null
                        }
                        {
                            maxValue ? <Typography variant='body2' noWrap>Max <br /> {maxValue}</Typography> : null
                        }
                        {
                            avgValue ? <Typography variant='body2' noWrap>Avg <br /> {avgValue}</Typography> : null
                        }
                    </Stack>
                    <Stack direction={'row'} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        {
                            minDate ? <Typography variant='body1' noWrap>min Date <br /> {minDate}</Typography> : null
                        }
                        {
                            maxDate ? <Typography variant='body1' noWrap>max Date <br /> {maxDate}</Typography> : null
                        }
                    </Stack>
                </Stack>
                <Stack alignSelf={'flex-end'}>
                    {
                        tempMeters ? tempMeters : null
                    }
                </Stack>
            </CardActions>
        </Card>
    )
}
