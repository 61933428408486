import { Box, Card, CardActions, CardMedia, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { AuthenticationContext } from '../../context/AuthenticationContext'
import { WEB_KEY } from '../../api/AxiosApi'
import { WmcContext } from '../../context/WmcContext'
import Image from 'mui-image'

function WmsHeader() {
    const { LoggedInUserInfo } = useContext(AuthenticationContext)
    const { palette, typography, breakpoints, shape: { }, spacing } = useTheme()
    const { BrandLogo } = useContext(WmcContext)
    const matches = useMediaQuery(breakpoints.down('xl'));

    const LOGIN_ID = localStorage.getItem('LoginID')
    const LOC_CODE = localStorage.getItem('LCODE')
    const ImageUrl = `https://log.solaramr.com/api/CMN_02/GET_COM_LOGO?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=xxxxx&WEB_KEY=${WEB_KEY}`

    return (
        <Stack rowGap={spacing(1)}>
            <Card sx={{ bgcolor: "primary.light" }}>
                <CardActions>
                    <marquee>
                        <Typography variant='body1' color={'background.default'}>
                            Industrial Energy Management System Flow Meter & Ground Water Monitoring Smart Metering and DLMS Solution Industrial Energy Management System Flow Meter & Ground Water Monitoring
                        </Typography>
                    </marquee>
                </CardActions>
            </Card>
            <Grid container spacing={spacing(1)}>
                <Grid item xs={12} sm={12} md={4}>
                    <Card sx={{ height: matches ? 50 : 70, pl: 2, display: "flex", alignItems: 'center' }}>
                        <Box>
                            <Typography variant='body1'>{LoggedInUserInfo?.username}</Typography>
                            <Typography variant='body1'>{LoggedInUserInfo?.Address}</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Card sx={{ height: matches ? 50 : 70, pl: 2, display: "flex", alignItems: 'center' }}>
                        <Typography variant='body1'>{LoggedInUserInfo?.MSG}</Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} display={'flex'} justifyContent={'center'}>
                    <Image fit='fill'
                        wrapperStyle={{ height: matches ? 50 : 70 }}
                        src={ImageUrl ? ImageUrl : require('../../assets/img/basic/battery.png')} />
                </Grid>
            </Grid>

        </Stack>

    )
}

export default WmsHeader
