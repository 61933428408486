import "./MsitMain.css"
import Lamp from "../../../../assets/img/logos/lamp.png";
import Charge from "../../../../assets/img/logos/charge.svg";
import { Link } from "react-router-dom";
import TopHeader from "../../../../components/general/TopHeader";

export default function Msit() {
    return (
        <>

            <TopHeader />
            <div className="container-fluid">
                <div className="row container-fluid my-4  First ">
                    <div className="col-md-4 mb-2">
                        <div className="msit1" style={{ marginRight: "11px" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <div>
                                    <img style={{ height: "2rem", width: "2rem" }} src={Lamp} alt="lamp" />
                                </div>

                                <div>
                                    <img style={{ height: "2rem", width: "2rem" }} src={Charge} alt="charge" />
                                </div>

                            </div>
                            <div className="list1">
                                <ul>
                                    <li>Today Yield</li>
                                    <li style={{ color: "blue" }}>1.8kwh</li>
                                    <li>Output Power</li>
                                    <li style={{ color: "green" }}>1.6kwh</li>

                                </ul>
                            </div>

                            <div className="list1">
                                <ul>
                                    <li>Today Yield</li>
                                    <li style={{ color: "blue" }}>1.8kwh</li>
                                    <li>Input Power</li>
                                    <li style={{ color: "green" }}>1.6kwh</li>

                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-4 mb-2">
                        <div className="msit1" style={{ marginRight: "11px" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <div>
                                    <img style={{ height: "2rem", width: "2rem" }} src={Lamp} alt="lamp" />
                                </div>

                                <div>
                                    <img style={{ height: "2rem", width: "2rem" }} src={Charge} alt="charge" />
                                </div>

                            </div>
                            <div className="list1">
                                <ul>
                                    <li>Today Yield</li>
                                    <li style={{ color: "blue" }}>1.8kwh</li>
                                    <li>Output Power</li>
                                    <li style={{ color: "green" }}>1.6kwh</li>

                                </ul>
                            </div>

                            <div className="list1">
                                <ul>
                                    <li>Today Yield</li>
                                    <li style={{ color: "blue" }}>1.8kwh</li>
                                    <li>Input Power</li>
                                    <li style={{ color: "green" }}>1.6kwh</li>

                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-4 mb-2">
                        <div className="msit1">
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <div>
                                    <img style={{ height: "2rem", width: "2rem" }} src={Lamp} alt="lamp" />
                                </div>

                                <div>
                                    <img style={{ height: "2rem", width: "2rem" }} src={Charge} alt="charge" />
                                </div>

                            </div>
                            <div className="list1">
                                <ul>
                                    <li>Today Yield</li>
                                    <li style={{ color: "blue" }}>1.8kwh</li>
                                    <li>Output Power</li>
                                    <li style={{ color: "green" }}>1.6kwh</li>

                                </ul>
                            </div>

                            <div className="list1">
                                <ul>
                                    <li>Today Yield</li>
                                    <li style={{ color: "blue" }}>1.8kwh</li>
                                    <li>Input Power</li>
                                    <li style={{ color: "green" }}>1.6kwh</li>

                                </ul>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid">
                <div className="row container-fluid my-2  Second">

                    <div className="col-md-2 mb-2">
                        <div className="msit2">

                            <div className="" style={{ marginTop: ".3rem" }}>
                                <img style={{ height: "2rem", width: "2rem", color: "green" }} src={Charge} alt="charge" />
                            </div>

                            <div className="list1">
                                <ul>
                                    <li>Site Capacity</li>
                                    <li>80KW</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-2 mb-2">
                        <div className="msit2">

                            <div className="" style={{ marginTop: ".3rem" }}>
                                <img style={{ height: "2rem", width: "2rem", color: "green" }} src={Charge} alt="charge" />
                            </div>

                            <div className="list1">
                                <ul>
                                    <li>Site Capacity</li>
                                    <li>80KW</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-2 mb-2">
                        <div className="msit2">

                            <div className="" style={{ marginTop: ".3rem" }}>
                                <img style={{ height: "2rem", width: "2rem", color: "green" }} src={Charge} alt="charge" />
                            </div>

                            <div className="list1">
                                <ul>
                                    <li>Site Capacity</li>
                                    <li>80KW</li>
                                   
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-2 mb-2">
                        <div className="msit2">

                            <div className="" style={{ marginTop: ".3rem" }}>
                                <img style={{ height: "2rem", width: "2rem", color: "green" }} src={Charge} alt="charge" />
                            </div>

                            <div className="list1">
                                <ul>
                                    <li>Site Capacity</li>
                                    <li>80KW</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-2 mb-2">
                        <div className="msit2">

                            <div className="" style={{ marginTop: ".3rem" }}>
                                <img style={{ height: "2rem", width: "2rem", color: "green" }} src={Charge} alt="charge" />
                            </div>

                            <div className="list1">
                                <ul>
                                    <li>Site Capacity</li>
                                    <li>80KW</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-2 mb-2">
                        <div className="msit2">

                            <div className="" style={{ marginTop: ".3rem" }}>
                                <img style={{ height: "2rem", width: "2rem", color: "green" }} src={Charge} alt="charge" />
                            </div>

                            <div className="list1">
                                <ul>
                                    <li>Site Capacity</li>
                                    <li>80KW</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>
            </div>


            <div className="row container-fluid my-2 mx-2 third_log text-start">
                <ul>
                    <li style={{ fontSize: "14px", fontWeight: "bolder" }}><Link to=''><button className="small_font">String Inverter</button></Link></li>
                    <li style={{ fontSize: "14px" }}><Link to='windspeed'><button className="small_font">Weather Station</button></Link></li>
                    <li style={{ fontSize: "14px" }}><Link to='irradiation'><button className="small_font">D-LOG</button></Link></li>

                </ul>
            </div>
        </>
    )
}


